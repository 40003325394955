<template>
  <div class="member_recharge">
    <div class="balance" v-if="productLists.length>0">
      <div>
        <p class="balance_title">钱包余额:</p>
        <p class="balance_price">{{user_credit/100}} {{productLists[mealsIndex].currency}}</p>
      </div>
      <div>
        <p class="balance_title">推广奖励金额:</p>
        <p class="balance_price">{{reward_credit/100}} {{productLists[mealsIndex].currency}}</p>
      </div>
    </div>
    <div class="meals_box" v-if="productLists.length>0">
      <div
        v-for="(item,index) in productLists"
        :key="item.order_id"
        @click="choseMeals(index)"
        :class="[{'meals_active':mealsIndex==index},'meals']"
        :style="{marginRight:(index+1)%3==0?0:'2.5%'}"
      >
        <p class="price">
          <span class="main_price">{{item.price/100}}</span>
          <span class="currency">{{item.currency}}</span>
        </p>
        <p class="sale_price">
          售价
          <span class="inner_price">{{item.price/100}}{{item.currency}}</span>
        </p>
      </div>
    </div>
    <div class="buy_ways">
      <h1 class="title">选择支付方式</h1>
      <div>
        <!-- <button class="buy_btn_active buy_btn">
          <img class="royal" src="../../assets/paylinx.svg" alt />
        </button> -->
        <button
          :class="[{'buy_btn_active':payIndex==index},'buy_btn']"
          v-for="(value,index) in payWays"
          :key="index"
          @click="payChose(index)"
        >
          <img v-if="index==0" class="royal" src="../../assets/royalpay.png" alt />
          <img v-if="index==1" class="paypal" src="../../assets/paypal2.png" alt />
          <span v-if="index==2">
            <img class="wallet" src="../../assets/wallet.png" alt />
            钱宝支付
          </span>
        </button>
        <van-radio-group v-model="channel" direction="horizontal" v-if="payIndex<1">
          <van-radio name="Wechat">微信支付</van-radio>
          <van-radio name="Alipay">支付宝支付</van-radio>
        </van-radio-group>
      </div>
      <div class="buy_result" v-if="chosedList">
        <p>
          <span>{{chosedList.price/100}}</span>
          <span>{{chosedList.currency}}</span>
        </p>
        <p v-if="channel=='Wechat'&&payIndex<1">
          <span>微信汇率</span>
          <span>{{wechat_rate}}</span>
        </p>
        <p v-if="channel=='Alipay'">
          <span>支付宝汇率</span>
          <span>{{alipay_rate}}</span>
        </p>
      </div>
      <button class="buy_now" @click="submit">立即购买</button>
    </div>
    <van-overlay :show="isShow" @click="closeMask">
      <div class="wrapper">
        <div class="block1" v-if="!status">
          <div class="qr_inner_box">
            <h1>{{channel=='Wechat'?'微信支付':'支付宝支付'}}</h1>
            <div id="member_qrcode"></div>
          </div>
        </div>

        <div class="block" v-else>
          <div class="inner_box">
            <i
              style="font-size:50px"
              :class="{'el-icon-circle-check':status==1,'el-icon-circle-close':status==2||status==3}"
            ></i>
            <p style="font-size:20px;margin-top:20px;">{{statusStr[status]}}</p>
            <div v-if="status==1">
              <router-link style="width:40%" to="/admin_center/myorder">
                <button class="have_look">查看订单</button>
              </router-link>
              <button class="hang_out" @click="closeMask">继续逛逛</button>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import QRcode from "qrcodejs2";
import {
  getProductListApi,
  getProductRateApi,
  submitOrderApi,
  getOrderStatusApi
} from "api/Recharge";
import { getWalletInfoApi } from "api/AdminCenter";
export default {
  computed: {
    chosedList: function() {
      return this.productLists[this.mealsIndex];
    }
  },

  data() {
    return {
      type: {
        1: "APP客户端",
        2: "加速盒",
        3: "家庭加速盒",
        4: "直连账户",
        5: "账户充值",
        6: "APP+家庭加速盒",
      },
      payWays: [
        { value: "RoyalPay" },
        { value: "Paypal" },
      ],
      productLists: [],
      mealsIndex: 0, //当前选择的套餐序列
      payIndex: 0, //当前选择的支付序列  0：royalpay,1:钱包
      channel: "Wechat", //royalpay默认选择微信支付
      rate:{
        RoyalPay:{
          alipay_rate: "", //支付宝汇率
          wechat_rate: "", //微信汇率
        },
        Paylinx:{
          alipay_rate: "", //支付宝汇率
          wechat_rate: "", //微信汇率
        }
      },
      alipay_rate: "", //支付宝汇率
      wechat_rate: "", //微信汇率
      isShow: false, //支付二维码弹窗
      status: "", //订单状态
      statusStr: {
        0: "待支付",
        1: "支付成功",
        2: "支付失败",
        3: "订单超时未支付"
      },
      timer: null,
      user_credit: "",
      reward_credit: ""
    };
  },
  methods: {
    choseMeals(index) {
      this.mealsIndex = index;
    },
    //选择支付方式 ---->  钱包/royalpay
    payChose(index) {
      this.payIndex = index;
      this.changeRate(index)
    },
    changeRate(index){
      if (this.payWays[index].value == 'RoyalPay') {
        this.wechat_rate = this.rate.RoyalPay.wechat_rate
        this.alipay_rate = this.rate.RoyalPay.alipay_rate
      }
       if (this.payWays[index].value == 'Paylinx') {
        this.wechat_rate = this.rate.Paylinx.wechat_rate
        this.alipay_rate = this.rate.Paylinx.alipay_rate
      }
    },
    //提交订单
    submit() {
      const loading = this.$loading({
        lock: true,
        text: "提交订单中。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const params = {
        payment_type: this.payWays[this.payIndex].value,
        product_id: this.productLists[this.mealsIndex].id,
        channel: this.channel,
        mode: 'mobile'
      };
      let winOpen = window.open("", "_blank");

      submitOrderApi(params)
        .then(res => {
          if (res.code == 0) {
            if (params.payment_type == 'Paypal') {
              setTimeout(() => {
                winOpen.location = res.data.code_url;
              }, 100);
                // window.open(res.data.code_url, '_blank');
            } else {
              this.isShow = true;
              window.open(res.data.code_url);
              document.getElementById('member_qrcode').innerHTML="";
              setTimeout(() => {
                let shareUrl = res.data.code_url;
                let qrcode = new QRcode("member_qrcode", {
                  text: shareUrl,
                  width: 200, //图像宽度
                  height: 200, //图像高度
                  colorDark: "#517cfc",
                  colorLight: "#ffffff",
                  correctLevel: QRcode.CorrectLevel.H
                });
                qrcode.clear(); //清除二维码
                qrcode.makeCode(shareUrl); //生成另一个新的二维码
                this.timer = setInterval(
                  () => this.getResult(res.data.order_id),
                  1000
                );
              }, 500);
            }
          }
        })
        .finally(() => {
          loading.close();
          this.isLoading = false;
        });
    },
    //查看支付订单状态
    getResult(order_id) {
      if (this.isShow) {
        getOrderStatusApi({ order_id }).then(res => {
          if (res.code == 0) {
            const { status } = res.data;
            //订单状态:0待支付/1支付成功/2支付失败/3订单关闭
            if (status != 0) {
              this.status = status;
              clearInterval(this.timer);
            }
          } else {
            clearInterval(this.timer);
            this.isShow = false;
          }
        });
      }
    },
    //关闭弹窗
    closeMask() {
      this.isShow = false;
      this.status = 0;
      clearInterval(this.timer);
    }
  },
  mounted() {
    //获取列表
    getProductListApi({type:5}).then(res => {
      if (res.code == 0) {
        this.productLists = res.data;
      }
    });
    //获取汇率
    getProductRateApi({payment_type:"RoyalPay"}).then(res => {
      if (res.code == 0) {
        if (res.data.RoyalPay != undefined) {
          this.rate.RoyalPay.wechat_rate = res.data.RoyalPay.wechat_rate
          this.rate.RoyalPay.alipay_rate = res.data.RoyalPay.alipay_rate
        }

        if (res.data.Paylinx != undefined) {
          this.rate.Paylinx.wechat_rate = res.data.Paylinx.wechat_rate
          this.rate.Paylinx.alipay_rate = res.data.Paylinx.alipay_rate
        }

        this.changeRate(0)
      }
    });
    //获取钱包信息
    getWalletInfoApi().then(res => {
      if (res.code == 0) {
        const { user_credit, reward_credit } = res.data;
        this.user_credit = user_credit;
        this.reward_credit = reward_credit;
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.member_recharge {
  padding: 0 16px;
  padding-top:48px;
  .balance {
    margin: 30px 0;
    width: 341px;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 2px 9px 0px rgba(181, 193, 203, 0.3);
    border-radius: 4px;
    @include flex_around;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    text-align: left;
    .balance_title {
      color: #091d40;
      padding-bottom: 10px;
    }
    .balance_price {
      color: #517cfc;
    }
  }
  .meals_box {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px dashed #bcc5d4;
    justify-content: center;
    .meals_active {
      border: 2px solid #517cfc;
    }
    .meals {
      width: 31%;
      text-align: center;
      margin-bottom: 45px;
      margin-right: 2%;
      background: #edf9ff;
      border-radius: 4px;
      padding: 10px 0;

      .price {
        font-size: 24px;
        font-weight: 600;
        color: #517cfc;
        margin-bottom: 10px;
        .main_price {
          letter-spacing: -1px;
        }
        .currency {
          font-size: 12px;
        }
      }
      .sale_price {
        font-size: 12px;
        font-weight: 600;
        color: #bcc5d4;
      }
    }
  }
  .buy_ways {
    padding-top: 30px;
    .title {
      text-align: left;
    }
    .buy_btn {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      border-radius: 5px;
      border: 1px solid #bcc5d4;
      font-weight: 500;
      color: #202020;
      margin: 20px 0;
      background: #fff;
      text-align: center;
      justify-content: center;
      font-size: 16px;
      .royal {
        width: 100px;
        height: 24px;
      }
      .paylinx{
        width: 110px;
        height: 30px;
      }
      .paypal{
        width: 100px;
        height: 24px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .wallet {
        height: 28px;
        margin-right: 10px;
      }
    }
    .buy_btn_active {
      border: 1px solid #517cfc;
    }
    .van-radio-group--horizontal {
      justify-content: around;
      margin-bottom: 18px;
    }
    .buy_result {
      p {
        @include flex_between;
        margin-bottom: 10px;
        font-weight: 500;
        span {
          font-size: 16px;
          color: #7e7e7e;
        }
      }
    }
    .buy_now {
      margin: 30px auto;
      width: 100%;
      height: 48px;
      background: #517cfc;
      border-radius: 5px;
      font-size: 16px;
      color: #fff;
      font-weight: 500;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block1 {
      width: 85%;
      height: 300px;
      background-color: #fff;
      .qr_inner_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          font-weight: 600;
          font-size: 28px;
          margin: 20px 0;
        }
      }
    }
    .block {
      width: 85%;
      height: 300px;
      background-color: #fff;

      .inner_box {
        padding: 40px 0;
        text-align: center;
        padding-top: 60px;
        .el-icon-circle-check {
          color: green;
        }
        button {
          height: 40px;
          line-height: 40px;
          color: #fff;
          background: #517cfc;
          font-size: 16px;
          text-align: center;
          width: 40%;
        }
        .hang_out {
          margin-left: 10%;
          margin-top: 60px;
        }
      }
    }
  }
}
</style>